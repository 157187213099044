<template>
    <div class="row">
        <div class="col-xl-4 col-lg-5">
            <div class="card text-center">
                <div class="card-body">
                    <img alt="profile-image" class="rounded-circle avatar-lg img-thumbnail"
                         src="../../../assets/images/users/avatar-1.svg">

                    <h4 class="mb-0 mt-2">{{user.first_name}}&nbsp;{{user.last_name}}</h4>
                    <p class="text-muted font-14">STIL Member</p>

                    <div class="text-left mt-3">
                        <h4 class="font-13 text-uppercase">About Me :</h4>
                        <p class="text-muted font-13 mb-3">
                            Hi I'm {{user.first_name}}&nbsp;{{user.last_name}},has been the industry's standard dummy
                            text ever since the
                            1500s, when an unknown printer took a galley of type.
                        </p>
                        <p class="text-muted mb-2 font-13"><strong>Full Name :</strong> <span class="ml-2">{{user.first_name}}&nbsp;{{user.last_name}}</span>
                        </p>

                        <p class="text-muted mb-2 font-13"><strong>Mobile :</strong><span class="ml-2">NA</span></p>

                        <p class="text-muted mb-2 font-13"><strong>Email :</strong> <span
                                class="ml-2 ">{{user.email}}</span>
                        </p>

                        <p class="text-muted mb-1 font-13"><strong>Location :</strong> <span class="ml-2">India</span>
                        </p>
                    </div>

                </div> <!-- end card-body -->
            </div> <!-- end card -->

        </div> <!-- end col-->

        <div class="col-xl-8 col-lg-7">
            <div class="card">
                <div class="card-body">
                    <form>
                        <h5 class="mb-4 text-uppercase"><i class="mdi mdi-account-circle mr-1"></i> Personal
                            Info</h5>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="firstname">First Name</label>
                                    <input class="form-control" id="firstname" placeholder="Enter first name"
                                           type="text"
                                           v-model="user.first_name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="lastname">Last Name</label>
                                    <input class="form-control" id="lastname" placeholder="Enter last name"
                                           type="text"
                                           v-model="user.last_name">
                                </div>
                            </div> <!-- end col -->
                        </div> <!-- end row -->

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="userbio">Bio</label>
                                    <textarea class="form-control" id="userbio" placeholder="Write something..."
                                              rows="4"></textarea>
                                </div>
                            </div> <!-- end col -->
                        </div> <!-- end row -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="useremail">Email Address</label>
                                    <input class="form-control" id="useremail" placeholder="Enter email"
                                           type="email"
                                           v-model="user.email">
                                    <span class="form-text text-muted"><small>If you want to change password please
                                        <router-link
                                                :to="{name: 'changepassword'}">click</router-link> here.</small></span>
                                </div>
                            </div>
                        </div> <!-- end row -->

                        <div class="text-right">
                            <button class="btn btn-success mt-2" disabled type="submit"><i
                                    class="mdi mdi-content-save"></i> Save
                            </button>
                        </div>
                    </form>
                </div> <!-- end card body -->
            </div> <!-- end card -->
        </div> <!-- end col -->
    </div>
    <!-- end row-->
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Profile",
        computed: {
            ...mapGetters(["currentUser"])
        },
        data() {
            return {
                user: {
                    id: null,
                    user_id: null,
                    email: null,
                    first_name: null,
                    last_name: null,
                    did: null
                }
            }
        },
        mounted() {
            this.getUser()
        },
        methods: {
            async getUser() {
                try {
                    let {data} = await this.$http.get(`users/${this.currentUser.id}`);
                    this.user = data;
                } catch (e) {

                }
            }
        }
    }
</script>

<style scoped>

</style>